window.onload = () => {
  //Capire come inserire CSS prima di caricare il js con webpacker

  const doc = document.documentElement;

  doc.classList.remove("no-js");
  doc.classList.add("js");

  // Accordion component
  const accordionEl = document.getElementsByClassName("accordion-title");

  if (accordionEl.length) {
    for (let i = 0; i < accordionEl.length; i++) {
      accordionEl[i].addEventListener("click", function () {
        this.parentNode.classList.toggle("is-open");
        const panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    }
  }

  (function (cb) {
    // browser event has already occurred.
    if (document.readyState === "complete") {
      return setTimeout(cb);
    }

    // Mozilla, Opera and webkit style
    if (window.addEventListener) {
      return window.addEventListener("load", cb, false);
    }

    // If IE event model is used
    if (window.attachEvent) {
      return window.attachEvent("onload", cb);
    }
  })(function () {
    document.querySelectorAll(".nav-btn").forEach(function (el) {
      el.addEventListener("click", function () {
        var nav = this.parentElement.parentElement,
          _class = "open";

        nav.classList.contains(_class)
          ? nav.classList.remove(_class)
          : nav.classList.add(_class);
      });
    });
  });

  document.querySelectorAll(".portion").forEach(function (el) {
    el.addEventListener("click", function () {
      console.log("aio");
      var nav = document.querySelector(".nav"),
        _class = "open";

      nav.classList.contains(_class)
        ? nav.classList.remove(_class)
        : nav.classList.add(_class);
    });
  });
};
